import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { ImagesCarousel } from "@outdoormap/gatsby-plugin-brand-sites"

import Map from "../map"
import SitesCarousel from "../sites-carousel"
import PostsCarousel from "../posts-carousel"
import Infobox from "../infobox"
import Styled from "../styled"
import { Heading1 } from "../styled/heading"
import { Label3 } from "../styled/label"
import { ParagraphLarge } from "../styled/paragraph"

const Article = styled.article`
  margin-bottom: ${rem(40)};
`

const Header = styled.header`
  min-height: ${({ $hasImages }) => ($hasImages ? `70vh` : rem(280))};
  margin-bottom: ${rem(50)};
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.darkGreenAlt};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(20)};
  }
`

const HeaderText = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  div {
    ${({ theme }) => theme.grid.container()}

    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    top: ${rem(-12)};
  }
`

const Date = styled.time`
  ${({ theme }) => theme.fonts.set(`secondary`, `normal`)};

  margin-bottom: ${rem(20)};
  padding: ${rem(10)} ${rem(25)} ${rem(8)} ${rem(25)};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 999em;
  font-size: ${rem(14)};
  display: flex;
  align-items: center;
  z-index: 10;
`

const Title = styled(Heading1)`
  margin-bottom: ${rem(40)};
  font-weight: bold;
  text-align: left;
  line-height: ${rem(40)};
  z-index: 10;

  @media ${({ theme }) => theme.mq.mediumDown} {
    line-height: ${rem(30)};
  }
`

const Tagline = styled(ParagraphLarge)`
  margin-top: 0.75em;
  z-index: 10;
`

const Images = styled.div`
  width: 100%;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    );
  }
`

const Content = styled(Styled)`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
`

const InfoboxContainer = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${rem(30)};

  &:empty {
    display: none;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${rem(20)};
  }
`

const Sites = styled.footer`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  > div {
    margin-top: ${rem(40)};
    padding-top: ${rem(40)};
    border-top: 1px solid ${({ theme }) => theme.colors.greenAlt};
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
    color: ${({ theme }) => theme.colors.greenAlt};
  }
`

const Other = styled.section`
  background-color: ${({ theme }) => theme.colors.yellowAlt};

  > div {
    ${({ theme }) => theme.grid.container()}

    padding-top: ${rem(50)};
    padding-bottom: ${rem(50)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${rem(20)};
      padding-top: ${rem(20)};
      padding-bottom: ${rem(20)};
    }
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
    color: ${({ theme }) => theme.colors.greenAlt};
  }
`

const MapWrap = styled.section`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  height: 50vh;
  z-index: 10;
  position: relative;
  margin-top: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    height: 80vh;
  }

  * {
    border-radius: 15px;
  }
`

const Post = ({ data, other, otherHeading }) => {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          mentionedInThisPost
          documents
        }
      }
    }
  `)

  const mapQuery = data.sites
    ? data.sites.map((s) => `site=${s.id}`).join(`&`)
    : null

  if (!data) return null

  return (
    <React.Fragment>
      <Article>
        <Header $hasImages={!!data.images?.length}>
          {!!data.images?.length && (
            <Images>
              <ImagesCarousel
                data={data.images.sort((a, b) => a.position - b.position)}
                title={data.title}
                fullscreenReady={false}
              />
            </Images>
          )}

          <HeaderText>
            <div>
              {data.date && (
                <Date
                  datetime={data.date}
                  dangerouslySetInnerHTML={{ __html: data.date }}
                />
              )}

              {data.title && (
                <Title dangerouslySetInnerHTML={{ __html: data.title }} />
              )}

              {data.tagline && (
                <Tagline dangerouslySetInnerHTML={{ __html: data.tagline }} />
              )}
            </div>
          </HeaderText>
        </Header>

        {!!data.body && (
          <Content dangerouslySetInnerHTML={{ __html: data.body }} />
        )}

        <InfoboxContainer>
          <Infobox data={data} />
        </InfoboxContainer>

        {mapQuery && (
          <React.Fragment>
            <MapWrap>
              <Map
                query={mapQuery}
                menu="fullscreen"
                scrollZoom="false"
                styleControl="fullscreen"
                zoomControl="fullscreen"
                measureControl="fullscreen"
                locationControl="fullscreen"
                printControl="fullscreen"
              />
            </MapWrap>

            <Sites>
              <div>
                <Label3
                  as="h2"
                  dangerouslySetInnerHTML={{
                    __html: `${translations.global.mentionedInThisPost}…`,
                  }}
                />

                <SitesCarousel visibleSlides={2} data={data.sites} />
              </div>
            </Sites>
          </React.Fragment>
        )}
      </Article>

      {other && !!other.nodes.length && (
        <Other>
          <div>
            {otherHeading && (
              <Label3
                as="h2"
                dangerouslySetInnerHTML={{ __html: otherHeading }}
              />
            )}

            <PostsCarousel visibleSlides={3} data={other.nodes} />
          </div>
        </Other>
      )}
    </React.Fragment>
  )
}

export default Post
